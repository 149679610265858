// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800;900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Amiri+Quran&display=swap');

@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  // --body-font: "Inter", sans-serif;
  --body-font: "Barlow", sans-serif;
  --body-color: #f9fbfc;
  --primary-color: #0085ff;
  --secondary-color: #f3f3f3;
  --border-color: #2b3b58;
  --bs-border-color: #2b3b58;
  --border-1st-color: #e2e2e2;
  --border-2nd-color: #dbdbdb;
  --highlight-color: #ff4949;
  --highlight-2nd-color: #ffd3c6;
  --icon-color: #ffffff;
  --icon-2nd-color: #d9d9d9;
  --heading-1st-color: #444444;
  --para-1st-color: #888888;
  --para-2nd-color: #222222;
  --para-3rd-color: #888888;
  --para-4th-color: #00a469;
  --para-5th-color: #666666;
  --para-6th-color: #666666;
  --box-1st-color: #f3f3f3;
  --box-2nd-color: #f9fbfc;
  --btn-1st-color: #dddddd;
  --btn-2nd-color: #bdf8db;
  --section-1st-color: #ffffff;
  --box-shadow: 0px 4px 26px rgba(222, 222, 222, 0.25);
  --box-2nd-shadow: 0px 4px 26px rgba(189, 189, 189, 0.25);
  --hero-section-bg: #f9fbfcc9;
  --font-icon-color: #171954;
  --scroll-color: #e41e1d;
  --post-border-color: #2b3b58;
  --input-field: #ffff;
  --input-text: #000000;
  --ongoing-call: #5cb85c;
  --link-color: #0085ff;
}

.dark-ui {
  --body-color: #000000;
  --primary-color: #0085ff;
  --secondary-color: #f3f3f3;
  --border-color: #2b3b58;
  --bs-border-color: #2b3b58;
  --border-1st-color: #2b3b58;
  --border-2nd-color: #2b3b58;
  --icon-color: #888888;
  --icon-alt-color: #a6acb6;
  --heading-1st-color: #ffffff;
  --para-1st-color: #a6acb6;
  --para-2nd-color: #ffffff;
  --para-3rd-color: #ffffff;
  --para-4th-color: #ffffff;
  --para-5th-color: #ffffff;
  --para-6th-color: #a6acb6;
  --box-1st-color: #2b3b58;
  --box-2nd-color: #2b3b58;
  --btn-1st-color: #0085ff;
  --btn-2nd-color: #185e54;
  --section-1st-color: #212f48;
  --box-shadow: 0px 4px 26px rgba(66, 66, 66, 0.25);
  --box-2nd-shadow: 0px 4px 26px rgba(66, 66, 66, 0.25);
  --hero-section-bg: #000428bf;
  --font-icon-color: #ffffff;
  --scroll-color: #e41e1d;
  --post-border-color: #f3f3f3;
  --input-field: #ffff;
  --input-text: #000000;
  --ongoing-call: #5cb85c;
  --link-color: #ffffff;
}
.cursor {
  cursor: pointer;
}
.collapsing {
  transition: 0.3s;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--body-font);
  background-color: var(--body-color) !important;
  font-size: 18px;
  line-height: 30px;
  padding: 0;
  margin: 0;
  font-weight: 400;
  overflow-x: hidden;
}

section,
footer {
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

h1,
h1 > a,
h2,
h2 > a,
h3,
h3 > a,
h4,
h4 > a,
h5,
h5 > a,
h6,
h6 > a,
.heading,
label {
  font-family: var(--body-font) !important;
  color: var(--heading-1st-color) !important;
  font-weight: 600;
  display: block;
  text-transform: capitalize;
  transition: all 0.3s;
  span {
    font-size: inherit;
    font-family: inherit;
    color: inherit;
    line-height: inherit;
    font-weight: inherit;
  }
}

h1 > a:hover,
h2 > a:hover,
h3 > a:hover,
h4 > a:hover,
h5 > a:hover,
h6 > a:hover {
  color: var(--heading-1st-color);
}

a {
  display: inline-block;
}

a {
  text-decoration: none !important;
  outline: none;
  transition: 0.3s;

  &:hover {
    text-decoration: none;
    outline: none;
    color: var(--heading-1st-color);
    i,
    span {
      color: var(--primary-color);
    }
  }
}

img {
  max-width: 100%;
  height: auto;
}

.max-un {
  max-width: unset;
}

.d-rtl {
  direction: rtl;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}

button {
  border: none;
  cursor: pointer;
  padding: 0;
  color: var(--para-1st-color);
  background-color: transparent;
}

.link {
  color: #0085ff;
}

.single-input {
  margin-bottom: 16px;
}

input,
textarea {
  padding: 15px 20px;
  color: var(--para-1st-color);
  width: 100%;
  font-family: var(--body-font);
  outline-color: transparent;
  border-radius: 10px;
  font-size: 16px;
  background-color: var(--box-1st-color);
  border: 1px solid transparent;
  &::placeholder {
    opacity: 0.5;
    color: #a6acd1;
  }
  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid var(--primary-color);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="checkbox"] {
  width: initial;
  height: initial;
}

select {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  outline: none;
}

span {
  display: inline-block;
}

.table thead th,
.table td,
.table th {
  border: none;
}

.b-none {
  border: none !important;
}

.ovf-unset {
  overflow-x: unset !important;
}

iframe {
  border: 0;
  width: 100%;
}

.shadow-none {
  -webkit-box-shadow: none;
  box-shadow: none;
}

h1,
h1 > a {
  font-size: 76px;
  line-height: 98.8px;
  margin-top: -20px;
}

h2,
h2 > a {
  font-size: 57px;
  line-height: 74.1px;
  margin-top: -14px;
}

h3,
h3 > a {
  font-size: 43px;
  line-height: 55.9px;
  margin-top: -10px;
}

h4,
h4 > a {
  font-size: 32px;
  line-height: 41.6px;
  margin-top: -8px;
}

h5,
h5 > a {
  font-size: 24px;
  line-height: 31.2px;
  margin-top: -6px;
}

h6,
h6 > a {
  font-size: 18px;
  line-height: 23.4px;
  margin-top: -4px;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  color: var(--para-2nd-color) !important;
}

table thead tr th {
  font-size: 18px;
  line-height: 30px;
}

a,
p,
span,
li,
option {
  margin: 0;
  font-family: var(--body-font) !important;
  color: var(--para-2nd-color);
  font-size: 16px;
  font-weight: 400;
  overflow-wrap: anywhere;
}

fa-icon svg path {
  fill: var(--font-icon-color);
  opacity: 0.9;
}

li {
  font-size: inherit;
}

.d-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Circlehub {
  a {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #212129;
    border: 1px solid #4e4e70;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s all;
    i {
      color: var(--heading-1st-color);
      font-size: 18px;
    }
    &:hover {
      border-color: #ffcc00;
      i {
        color: var(--primary-color);
      }
    }
  }
}

.cus-border {
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}

.magnific-area {
  .mfp-iframe {
    width: 60px;
    height: 60px;
    border: 2px solid #ffffff;
    border-radius: 50%;
    i {
      color: var(--bs-white);
      &:hover {
        color: var(--bs-white);
      }
    }
  }
}

.section-text {
  margin-bottom: 35px;
}

.section-header {
  margin-bottom: 55px;
}

.cus-mar {
  margin-bottom: -24px;
}

.single-box {
  margin-bottom: 24px;
}

.sub-title {
  color: var(--primary-color);
  margin-bottom: 27px;
}

.title {
  margin-bottom: 10px;
}

.xxltxt {
  font-size: 32px;
  line-height: 41.6px;
  margin-top: -8px;
}
.xltxt {
  font-size: 26px;
  line-height: 36px;
  margin-top: -7px;
}
.lgtxt {
  font-size: 24px;
  line-height: 36px;
  margin-top: -7px;
}
.mdtxt {
  font-size: 18px;
  line-height: 22px;
}
.smtxt {
  font-size: 16px;
  line-height: 21px;
}

.cus-z {
  z-index: 1;
}

.cus-z2 {
  z-index: 2;
}

.pt-120 {
  padding-top: 120px;
}

@media (max-width: 991px) {
  .pt-120 {
    padding-top: 60px;
  }
  .header-section {
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.3);
  }
}

@media (max-width: 575px) {
  .pt-120 {
    padding-top: 50px;
  }
}

.pb-120 {
  padding-bottom: 120px;
}

@media (max-width: 991px) {
  .pb-120 {
    padding-bottom: 60px;
  }
}

@media (max-width: 575px) {
  .pb-120 {
    padding-bottom: 50px;
  }
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  background-color: var(--body-color);
}

*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--bs-primary);
}

.cus-scrollbar {
  &::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 3px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &:hover {
    *::-webkit-scrollbar-track {
      box-shadow: inset 0 0 2px grey;
      background-color: var(--body-color);
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--bs-primary);
    }
  }
}

// Scroll To Top
.scrollToTop {
  position: fixed;
  bottom: 0;
  right: 30px;
  width: 40px;
  height: 40px;
  background-color: var(--primary-color);
  border-radius: 5px;
  color: var(--body-color);
  line-height: 45px;
  font-size: 20px;
  text-align: center;
  z-index: 9;
  cursor: pointer;
  transition: all 1s;
  transform: translateY(100%);
  &:hover {
    color: var(--body-color);
  }
  &.active {
    bottom: 30px;
    transform: translateY(0%);
  }
}

// preloader
.preloader {
  width: 100%;
  height: 100vh;
  background-color: var(--secondary-color);
  position: fixed;
  display: flex;
  overflow: hidden;
  top: 0;
  z-index: 99999;
  .loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;
    &::before {
      content: "";
      box-sizing: border-box;
      position: absolute;
      inset: 0px;
      border-radius: 50%;
      border: 5px solid var(--highlight-color);
      animation: prixClipFix 2s linear infinite;
    }
  }
}

.accordion-button:focus {
  box-shadow: none;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes item {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

/* cmn btn */
.cmn-btn {
  background: var(--primary-color);
  padding: 4px 15px;
  color: var(--bs-white);
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  z-index: 0;
  border-radius: 10px;
  transition: 0.3s;

  &:disabled {
    background-color: var(--box-1st-color);

    &:hover {
      background-color: var(--box-1st-color);
    }
  }

  i,
  span {
    color: var(--bs-white);
  }
  &:hover {
    background: #036dcf;
    color: var(--bs-white);
    i,
    span {
      color: var(--bs-white);
    }
  }
  &.alt {
    background: #ffd3c6;
    color: #ff4747;
    i,
    span {
      color: #ff4747;
    }
    &:hover {
      background: #f44c4c;
      color: var(--bs-white);
      i,
      span {
        color: var(--bs-white);
      }
    }
  }
  &.third {
    background: var(--box-1st-color);
    color: var(--para-1st-color);
    i,
    span {
      color: var(--para-1st-color);
    }
    &:hover {
      background: var(--btn-1st-color);
      color: var(--para-3rd-color);
      i,
      span {
        color: var(--para-3rd-color);
      }
    }
  }
  &.fourth {
    background: var(--btn-2nd-color);
    color: var(--para-4th-color);
    i,
    span {
      color: var(--para-4th-color);
    }
    &:hover {
      color: var(--bs-white);
      background-color: #01ac5a;
      i,
      span {
        color: var(--bs-white);
      }
    }
  }
}

// Custom Dropdown
.cus-dropdown {
  .dropdown-menu {
    background: var(--section-1st-color);
    border: 1px solid var(--border-color);
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    width: max-content;
    inset: 25px 0px auto auto !important;
    transform: unset !important;
    cursor: pointer;
    span,
    button {
      font-size: 16px;
      i {
        color: var(--icon-2nd-color);
        line-height: 100%;
      }
    }
    li {
      margin-top: 10px;
      &:hover {
        span {
          color: var(--primary-color);
        }
      }
    }
    i {
      font-weight: 300;
    }
    .dropdown-item {
      &:hover {
        background-color: transparent;
        color: var(--primary-color);
      }
    }
  }
  .dropdown-toggle {
    &::after {
      display: none;
    }
  }
}

// Custom File Upload
.profile-picture {
  img {
    border-radius: 10px;
  }
}
.file-upload {
  input {
    display: none;
  }
  .file {
    cursor: pointer;
    position: relative;
    .file-custom {
      border-radius: 20px;
      border: 2px dashed var(--border-color);
      i {
        font-size: 100px;
        font-weight: 600;
      }
      span {
        color: var(--para-1st-color);
      }
    }
  }
}

// Slick Arrow Dot
.slick-arrow {
  position: absolute;
  outline: none;
  top: calc(50% - 10px);
  padding: 0;
  width: 45px;
  height: 35px;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  i {
    color: var(--bs-white);
    font-size: 24px;
  }
  &.slick-arrow {
    transition: 0.3s;
    opacity: 0;
  }
  &.arafat-prev {
    left: -10px;
  }
  &.arafat-next {
    right: -10px;
    transform: rotate(180deg);
  }
}
.section-dots {
  text-align: center;
  position: absolute;
  bottom: 55px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 20px);
  li {
    margin: 0 5px;
    display: inline-block;
    list-style: none;
    .dot {
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 10px;
      background-color: #e2d9fd;
      text-decoration: none;
      cursor: pointer;
      transition: all ease 0.3s;
      .string {
        position: absolute;
        line-height: 0;
        opacity: 0;
      }
    }
    &.slick-active {
      .dot {
        background-color: var(--primary-color);
      }
    }
  }
}

// Nice Select
.nice-select {
  background-color: transparent;
  border: transparent;
  border-radius: 5px;
  float: initial;
  padding: 0;
  display: flex;
  align-items: center;
  line-height: initial;
  height: 100%;
  width: 100%;
  &:focus,
  &:hover {
    border-color: transparent;
  }
  &::after {
    height: 10px;
    width: 10px;
    right: 5px;
    top: initial;
  }
  .list {
    width: 100%;
    background-color: var(--body-color);
    border: 1px solid var(--primary-color);
    box-shadow: 0;
    padding: 0;
  }
  .current {
    color: var(--para-1st-color);
    font-weight: 500;
    font-size: 16px;
    margin-right: 30px;
  }
  .option {
    background-color: transparent;
    font-size: 16px;
    line-height: 18px;
    padding: 4px 5px;
    min-height: initial;
    &:hover,
    &:focus,
    &.selected.focus {
      color: var(--primary-color);
      background-color: transparent;
    }
  }
}

// checkbox single
.checkbox-single {
  position: relative;
  padding-left: 40px;
  cursor: pointer;
  user-select: none;
  label {
    font-family: var(--body-font);
  }
  .checkbox-area {
    .checkmark {
      position: absolute;
      left: 0;
      height: 24px;
      width: 24px;
      border-radius: 5px;
      background-color: #50506b;
      &::after {
        content: "";
        position: absolute;
        display: none;
      }
    }
  }
  .checkmark {
    &::after {
      width: 16px;
      height: 11px;
      //   background-image: url(../images/icon/check.png);
      background-repeat: no-repeat;
    }
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
}

// Radio Button
.single-radio-item {
  border-radius: 10px;
  background-color: #38384b;
  border: 1px solid #58587d;
  margin-bottom: 20px;
  .single-radio {
    display: block;
    position: relative;
    font-family: var(--body-font);
    padding-left: 30px;
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    padding: 14px 40px;
    &:hover input ~ .checkmark {
      background-color: transparent;
    }
    .checkmark {
      position: absolute;
      left: 15px;
      height: 12px;
      width: 12px;
      border: 1px solid rgba(233, 233, 255, 0.5);
      background-color: transparent;
      border-radius: 50%;
    }
    input {
      position: absolute;
      border-radius: 0;
      opacity: 0;
      cursor: pointer;
    }
    input:checked ~ .checkmark {
      background-color: #ffcc00;
      border: 1px solid var(--hover-color);
      &::after {
        display: block;
        background: var(--hover-color);
      }
    }
  }
}

// Input Area
.input-area {
  border-radius: 10px;
  padding: 15px 20px;
  background-color: var(--box-1st-color);
  color: var(--heading-1st-color) !important;
  &:focus-within {
    outline: 1px solid var(--primary-color);
    border-color: transparent;
    color: var(--heading-1st-color) !important;
  }
  &.second {
    padding: 6px 10px;
    background-color: transparent;
    border: 1px solid var(--border-color);
    input {
      font-size: 16px;
    }
  }
  i {
    color: var(--para-3rd-color);
  }
  input,
  textarea {
    border-radius: 0;
    padding: 0;
    border: none;
    background: transparent;
    color: var(--heading-1st-color) !important;

    &::placeholder {
      // opacity: 0.5;
      // color: #a6acd1;
      opacity: 0.8;
      color: var(--para-2nd-color);
    }
  }
}

// Animation
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@keyframes rotation {
  100% {
    transform: rotatey(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes ripple2Ani {
  0% {
    transform: translate(0px, 0px);
  }
  33% {
    transform: translate(-5px, -5px);
  }
  66% {
    transform: translate(5px, 5px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes stretch {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes rippleAni {
  0% {
    transform: translate(0px, 0px);
  }
  33% {
    transform: translate(5px, -5px);
  }
  66% {
    transform: translate(-5px, 5px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

.jw-state-idle .jw-controls {
  background: unset !important;
}

.jw-controls-backdrop {
  background: unset !important;
}

::-webkit-scrollbar {
  width: 8px !important;
  background-color: #f5f5f5 !important;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background-color: var(--scroll-color) !important;
}


.login-page {
  justify-content: center;
  height: 100vh;
}

.registerForm {
  z-index: 99;
  background: rgba(255, 255, 255, 0.3215686275);
  padding: 20px;
  border-radius: 30px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.hrero-section {
  background-image: url("../../images/banner/banner-2.jpg");
}
