// Home Page Start
.header-section {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background-color: var(--body-color);
    &.header-fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        border-color: transparent;
        box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.3);
        background-color: var(--body-color);
    }
    .navbar-toggler {
        padding-right: 0;
        display: none;
        border: none;
        &:focus {
            box-shadow: none;
        }
        i {
            color: var(--primary-color);
        }
    }
    .navbar {
        .search-active{
            border-radius: 50%;
            background: var(--box-1st-color);
            padding: 8px;
        }
        form{
            b
            i{
                color: var(--para-3rd-color);
            }
        }
        .navbar-nav{
            a{
                text-transform: capitalize;
                font-weight: 600;
                background-color: transparent;
                color: var(--para-3rd-color);
                transition: 0.3s;
                i{
                    margin: 0;
                }
                &.active, &:hover {
                    color: var(--primary-color);
                }
            }
        }
        .dropdown-toggle{
            position: relative;
            z-index: 1;
            &::after {
                position: absolute;
                content: "\0044";
                border: none;
                font-family: "arafat font";
                font-weight: 400;
                transform: rotate(270deg);
                font-size: 10px;
                bottom: -2px;
                z-index: -1;
            }
        }
        .dropdown-menu{
            padding-right: 20px;
            a{
                padding: 3px 15px;
                &::after{
                    bottom: 1px;
                }
            }
        }
        .dropdown-menu, .sub-menu {
            background-color: var(--body-color);
            box-shadow: 0px 12px 12px rgba(44,44,77,0.49);
        }
        .sub-dropdown {
            position: relative;
            .sub-menu {
                display: none;
                position: absolute;
                left: 150px;
                top: 0;
                background-color: var(--section-1st-color);
                z-index: 1;
                a{
                    font-weight: 500;

                }
            }
        }
        .navbar-toggler:focus {
            outline: 0;
            box-shadow: none;
        }
        .show-dropdown{
            margin-right: 15px;
        }
        .show-dropdown:hover .dropdown-menu {
            display: block !important;
        }
        .sub-dropdown:hover .sub-menu {
            display: block !important;
        }
    }
}

// Header Menu area
