/*============================================
# Template Name: Circlehub
# Version: 1.0
# Date: 10/03/2023
# Author: uiaxis
# Author URI: https://themeforest.net/user/uiaxis
# Description: Circlehub - HTML Templates
============================================*/
/*============================================
======== Table of Css Content =========
# Typography
# Normalize
# Global style
# Header Area
# Banner Area
# Section Area
# Footer Area
============================================*/
@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fs-xxxl {
  font-size: 40px;
}

.fs-xxl {
  font-size: 34px;
}

.fs-xl {
  font-size: 30px;
}

.fs-lg {
  font-size: 24px;
}

.fs-md {
  font-size: 16px;
}

:root {
  --body-font: "Barlow", sans-serif;
  --body-color: #f9fbfc;
  --primary-color: #0085ff;
  --secondary-color: #f3f3f3;
  --border-color: #2b3b58;
  --bs-border-color: #2b3b58;
  --border-1st-color: #e2e2e2;
  --border-2nd-color: #dbdbdb;
  --highlight-color: #ff4949;
  --highlight-2nd-color: #ffd3c6;
  --icon-color: #ffffff;
  --icon-2nd-color: #d9d9d9;
  --heading-1st-color: #444444;
  --para-1st-color: #888888;
  --para-2nd-color: #222222;
  --para-3rd-color: #888888;
  --para-4th-color: #00a469;
  --para-5th-color: #666666;
  --para-6th-color: #666666;
  --box-1st-color: #f3f3f3;
  --box-2nd-color: #f9fbfc;
  --btn-1st-color: #dddddd;
  --btn-2nd-color: #bdf8db;
  --section-1st-color: #ffffff;
  --box-shadow: 0px 4px 26px rgba(222, 222, 222, 0.25);
  --box-2nd-shadow: 0px 4px 26px rgba(189, 189, 189, 0.25);
  --hero-section-bg: #f9fbfcc9;
  --font-icon-color: #171954;
  --scroll-color: #e41e1d;
  --post-border-color: #2b3b58;
  --input-field: #ffff;
  --input-text: #000000;
  --ongoing-call: #5cb85c;
  --link-color: #0085ff;
}

.dark-ui {
  --body-color: #000000;
  --primary-color: #0085ff;
  --secondary-color: #f3f3f3;
  --border-color: #2b3b58;
  --bs-border-color: #2b3b58;
  --border-1st-color: #2b3b58;
  --border-2nd-color: #2b3b58;
  --icon-color: #888888;
  --icon-alt-color: #a6acb6;
  --heading-1st-color: #ffffff;
  --para-1st-color: #a6acb6;
  --para-2nd-color: #ffffff;
  --para-3rd-color: #ffffff;
  --para-4th-color: #ffffff;
  --para-5th-color: #ffffff;
  --para-6th-color: #a6acb6;
  --box-1st-color: #2b3b58;
  --box-2nd-color: #2b3b58;
  --btn-1st-color: #0085ff;
  --btn-2nd-color: #185e54;
  --section-1st-color: #212f48;
  --box-shadow: 0px 4px 26px rgba(66, 66, 66, 0.25);
  --box-2nd-shadow: 0px 4px 26px rgba(66, 66, 66, 0.25);
  --hero-section-bg: #000428bf;
  --font-icon-color: #ffffff;
  --scroll-color: #e41e1d;
  --post-border-color: #f3f3f3;
  --input-field: #ffff;
  --input-text: #000000;
  --ongoing-call: #5cb85c;
  --link-color: #ffffff;
}

.cursor {
  cursor: pointer;
}

.collapsing {
  transition: 0.3s;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--body-font);
  background-color: var(--body-color) !important;
  font-size: 18px;
  line-height: 30px;
  padding: 0;
  margin: 0;
  font-weight: 400;
  overflow-x: hidden;
}

section,
footer {
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

h1,
h1 > a,
h2,
h2 > a,
h3,
h3 > a,
h4,
h4 > a,
h5,
h5 > a,
h6,
h6 > a,
.heading,
label {
  font-family: var(--body-font) !important;
  color: var(--heading-1st-color) !important;
  font-weight: 600;
  display: block;
  text-transform: capitalize;
  transition: all 0.3s;
}
h1 span,
h1 > a span,
h2 span,
h2 > a span,
h3 span,
h3 > a span,
h4 span,
h4 > a span,
h5 span,
h5 > a span,
h6 span,
h6 > a span,
.heading span,
label span {
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  line-height: inherit;
  font-weight: inherit;
}

h1 > a:hover,
h2 > a:hover,
h3 > a:hover,
h4 > a:hover,
h5 > a:hover,
h6 > a:hover {
  color: var(--heading-1st-color);
}

a {
  display: inline-block;
}

a {
  text-decoration: none !important;
  outline: none;
  transition: 0.3s;
}
a:hover {
  text-decoration: none;
  outline: none;
  color: var(--heading-1st-color);
}
a:hover i,
a:hover span {
  color: var(--primary-color);
}

img {
  max-width: 100%;
  height: auto;
}

.max-un {
  max-width: unset;
}

.d-rtl {
  direction: rtl;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}

button {
  border: none;
  cursor: pointer;
  padding: 0;
  color: var(--para-1st-color);
  background-color: transparent;
}

.link {
  color: #0085ff;
}

.single-input {
  margin-bottom: 16px;
}

input,
textarea {
  padding: 15px 20px;
  color: var(--para-1st-color);
  width: 100%;
  font-family: var(--body-font);
  outline-color: transparent;
  border-radius: 10px;
  font-size: 16px;
  background-color: var(--box-1st-color);
  border: 1px solid transparent;
}
input::placeholder,
textarea::placeholder {
  opacity: 0.5;
  color: #a6acd1;
}
input:focus,
textarea:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid var(--primary-color);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type=checkbox] {
  width: initial;
  height: initial;
}

select {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  outline: none;
}

span {
  display: inline-block;
}

.table thead th,
.table td,
.table th {
  border: none;
}

.b-none {
  border: none !important;
}

.ovf-unset {
  overflow-x: unset !important;
}

iframe {
  border: 0;
  width: 100%;
}

.shadow-none {
  -webkit-box-shadow: none;
  box-shadow: none;
}

h1,
h1 > a {
  font-size: 76px;
  line-height: 98.8px;
  margin-top: -20px;
}

h2,
h2 > a {
  font-size: 57px;
  line-height: 74.1px;
  margin-top: -14px;
}

h3,
h3 > a {
  font-size: 43px;
  line-height: 55.9px;
  margin-top: -10px;
}

h4,
h4 > a {
  font-size: 32px;
  line-height: 41.6px;
  margin-top: -8px;
}

h5,
h5 > a {
  font-size: 24px;
  line-height: 31.2px;
  margin-top: -6px;
}

h6,
h6 > a {
  font-size: 18px;
  line-height: 23.4px;
  margin-top: -4px;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  color: var(--para-2nd-color) !important;
}

table thead tr th {
  font-size: 18px;
  line-height: 30px;
}

a,
p,
span,
li,
option {
  margin: 0;
  font-family: var(--body-font) !important;
  color: var(--para-2nd-color);
  font-size: 16px;
  font-weight: 400;
  overflow-wrap: anywhere;
}

fa-icon svg path {
  fill: var(--font-icon-color);
  opacity: 0.9;
}

li {
  font-size: inherit;
}

.d-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Circlehub a {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #212129;
  border: 1px solid #4e4e70;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s all;
}
.Circlehub a i {
  color: var(--heading-1st-color);
  font-size: 18px;
}
.Circlehub a:hover {
  border-color: #ffcc00;
}
.Circlehub a:hover i {
  color: var(--primary-color);
}

.cus-border {
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}

.magnific-area .mfp-iframe {
  width: 60px;
  height: 60px;
  border: 2px solid #ffffff;
  border-radius: 50%;
}
.magnific-area .mfp-iframe i {
  color: var(--bs-white);
}
.magnific-area .mfp-iframe i:hover {
  color: var(--bs-white);
}

.section-text {
  margin-bottom: 35px;
}

.section-header {
  margin-bottom: 55px;
}

.cus-mar {
  margin-bottom: -24px;
}

.single-box {
  margin-bottom: 24px;
}

.sub-title {
  color: var(--primary-color);
  margin-bottom: 27px;
}

.title {
  margin-bottom: 10px;
}

.xxltxt {
  font-size: 32px;
  line-height: 41.6px;
  margin-top: -8px;
}

.xltxt {
  font-size: 26px;
  line-height: 36px;
  margin-top: -7px;
}

.lgtxt {
  font-size: 24px;
  line-height: 36px;
  margin-top: -7px;
}

.mdtxt {
  font-size: 18px;
  line-height: 22px;
}

.smtxt {
  font-size: 16px;
  line-height: 21px;
}

.cus-z {
  z-index: 1;
}

.cus-z2 {
  z-index: 2;
}

.pt-120 {
  padding-top: 120px;
}

@media (max-width: 991px) {
  .pt-120 {
    padding-top: 60px;
  }
  .header-section {
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.3);
  }
}
@media (max-width: 575px) {
  .pt-120 {
    padding-top: 50px;
  }
}
.pb-120 {
  padding-bottom: 120px;
}

@media (max-width: 991px) {
  .pb-120 {
    padding-bottom: 60px;
  }
}
@media (max-width: 575px) {
  .pb-120 {
    padding-bottom: 50px;
  }
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  background-color: var(--body-color);
}

*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--bs-primary);
}

.cus-scrollbar::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: transparent;
}
.cus-scrollbar::-webkit-scrollbar {
  width: 3px;
  background-color: transparent;
}
.cus-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.cus-scrollbar:hover *::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  background-color: var(--body-color);
}
.cus-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: var(--bs-primary);
}

.scrollToTop {
  position: fixed;
  bottom: 0;
  right: 30px;
  width: 40px;
  height: 40px;
  background-color: var(--primary-color);
  border-radius: 5px;
  color: var(--body-color);
  line-height: 45px;
  font-size: 20px;
  text-align: center;
  z-index: 9;
  cursor: pointer;
  transition: all 1s;
  transform: translateY(100%);
}
.scrollToTop:hover {
  color: var(--body-color);
}
.scrollToTop.active {
  bottom: 30px;
  transform: translateY(0%);
}

.preloader {
  width: 100%;
  height: 100vh;
  background-color: var(--secondary-color);
  position: fixed;
  display: flex;
  overflow: hidden;
  top: 0;
  z-index: 99999;
}
.preloader .loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}
.preloader .loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid var(--highlight-color);
  animation: prixClipFix 2s linear infinite;
}

.accordion-button:focus {
  box-shadow: none;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes item {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.fadeInDown {
  animation-name: fadeInDown;
}

/* cmn btn */
.cmn-btn {
  background: var(--primary-color);
  padding: 4px 15px;
  color: var(--bs-white);
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  z-index: 0;
  border-radius: 10px;
  transition: 0.3s;
}
.cmn-btn:disabled {
  background-color: var(--box-1st-color);
}
.cmn-btn:disabled:hover {
  background-color: var(--box-1st-color);
}
.cmn-btn i,
.cmn-btn span {
  color: var(--bs-white);
}
.cmn-btn:hover {
  background: #036dcf;
  color: var(--bs-white);
}
.cmn-btn:hover i,
.cmn-btn:hover span {
  color: var(--bs-white);
}
.cmn-btn.alt {
  background: #ffd3c6;
  color: #ff4747;
}
.cmn-btn.alt i,
.cmn-btn.alt span {
  color: #ff4747;
}
.cmn-btn.alt:hover {
  background: #f44c4c;
  color: var(--bs-white);
}
.cmn-btn.alt:hover i,
.cmn-btn.alt:hover span {
  color: var(--bs-white);
}
.cmn-btn.third {
  background: var(--box-1st-color);
  color: var(--para-1st-color);
}
.cmn-btn.third i,
.cmn-btn.third span {
  color: var(--para-1st-color);
}
.cmn-btn.third:hover {
  background: var(--btn-1st-color);
  color: var(--para-3rd-color);
}
.cmn-btn.third:hover i,
.cmn-btn.third:hover span {
  color: var(--para-3rd-color);
}
.cmn-btn.fourth {
  background: var(--btn-2nd-color);
  color: var(--para-4th-color);
}
.cmn-btn.fourth i,
.cmn-btn.fourth span {
  color: var(--para-4th-color);
}
.cmn-btn.fourth:hover {
  color: var(--bs-white);
  background-color: #01ac5a;
}
.cmn-btn.fourth:hover i,
.cmn-btn.fourth:hover span {
  color: var(--bs-white);
}

.cus-dropdown .dropdown-menu {
  background: var(--section-1st-color);
  border: 1px solid var(--border-color);
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  width: max-content;
  inset: 25px 0px auto auto !important;
  transform: unset !important;
  cursor: pointer;
}
.cus-dropdown .dropdown-menu span,
.cus-dropdown .dropdown-menu button {
  font-size: 16px;
}
.cus-dropdown .dropdown-menu span i,
.cus-dropdown .dropdown-menu button i {
  color: var(--icon-2nd-color);
  line-height: 100%;
}
.cus-dropdown .dropdown-menu li {
  margin-top: 10px;
}
.cus-dropdown .dropdown-menu li:hover span {
  color: var(--primary-color);
}
.cus-dropdown .dropdown-menu i {
  font-weight: 300;
}
.cus-dropdown .dropdown-menu .dropdown-item:hover {
  background-color: transparent;
  color: var(--primary-color);
}
.cus-dropdown .dropdown-toggle::after {
  display: none;
}

.profile-picture img {
  border-radius: 10px;
}

.file-upload input {
  display: none;
}
.file-upload .file {
  cursor: pointer;
  position: relative;
}
.file-upload .file .file-custom {
  border-radius: 20px;
  border: 2px dashed var(--border-color);
}
.file-upload .file .file-custom i {
  font-size: 100px;
  font-weight: 600;
}
.file-upload .file .file-custom span {
  color: var(--para-1st-color);
}

.slick-arrow {
  position: absolute;
  outline: none;
  top: calc(50% - 10px);
  padding: 0;
  width: 45px;
  height: 35px;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.slick-arrow i {
  color: var(--bs-white);
  font-size: 24px;
}
.slick-arrow.slick-arrow {
  transition: 0.3s;
  opacity: 0;
}
.slick-arrow.arafat-prev {
  left: -10px;
}
.slick-arrow.arafat-next {
  right: -10px;
  transform: rotate(180deg);
}

.section-dots {
  text-align: center;
  position: absolute;
  bottom: 55px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 20px);
}
.section-dots li {
  margin: 0 5px;
  display: inline-block;
  list-style: none;
}
.section-dots li .dot {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background-color: #e2d9fd;
  text-decoration: none;
  cursor: pointer;
  transition: all ease 0.3s;
}
.section-dots li .dot .string {
  position: absolute;
  line-height: 0;
  opacity: 0;
}
.section-dots li.slick-active .dot {
  background-color: var(--primary-color);
}

.nice-select {
  background-color: transparent;
  border: transparent;
  border-radius: 5px;
  float: initial;
  padding: 0;
  display: flex;
  align-items: center;
  line-height: initial;
  height: 100%;
  width: 100%;
}
.nice-select:focus, .nice-select:hover {
  border-color: transparent;
}
.nice-select::after {
  height: 10px;
  width: 10px;
  right: 5px;
  top: initial;
}
.nice-select .list {
  width: 100%;
  background-color: var(--body-color);
  border: 1px solid var(--primary-color);
  box-shadow: 0;
  padding: 0;
}
.nice-select .current {
  color: var(--para-1st-color);
  font-weight: 500;
  font-size: 16px;
  margin-right: 30px;
}
.nice-select .option {
  background-color: transparent;
  font-size: 16px;
  line-height: 18px;
  padding: 4px 5px;
  min-height: initial;
}
.nice-select .option:hover, .nice-select .option:focus, .nice-select .option.selected.focus {
  color: var(--primary-color);
  background-color: transparent;
}

.checkbox-single {
  position: relative;
  padding-left: 40px;
  cursor: pointer;
  user-select: none;
}
.checkbox-single label {
  font-family: var(--body-font);
}
.checkbox-single .checkbox-area .checkmark {
  position: absolute;
  left: 0;
  height: 24px;
  width: 24px;
  border-radius: 5px;
  background-color: #50506b;
}
.checkbox-single .checkbox-area .checkmark::after {
  content: "";
  position: absolute;
  display: none;
}
.checkbox-single .checkmark::after {
  width: 16px;
  height: 11px;
  background-repeat: no-repeat;
}
.checkbox-single input:checked ~ .checkmark:after {
  display: block;
}

.single-radio-item {
  border-radius: 10px;
  background-color: #38384b;
  border: 1px solid #58587d;
  margin-bottom: 20px;
}
.single-radio-item .single-radio {
  display: block;
  position: relative;
  font-family: var(--body-font);
  padding-left: 30px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  padding: 14px 40px;
}
.single-radio-item .single-radio:hover input ~ .checkmark {
  background-color: transparent;
}
.single-radio-item .single-radio .checkmark {
  position: absolute;
  left: 15px;
  height: 12px;
  width: 12px;
  border: 1px solid rgba(233, 233, 255, 0.5);
  background-color: transparent;
  border-radius: 50%;
}
.single-radio-item .single-radio input {
  position: absolute;
  border-radius: 0;
  opacity: 0;
  cursor: pointer;
}
.single-radio-item .single-radio input:checked ~ .checkmark {
  background-color: #ffcc00;
  border: 1px solid var(--hover-color);
}
.single-radio-item .single-radio input:checked ~ .checkmark::after {
  display: block;
  background: var(--hover-color);
}

.input-area {
  border-radius: 10px;
  padding: 15px 20px;
  background-color: var(--box-1st-color);
  color: var(--heading-1st-color) !important;
}
.input-area:focus-within {
  outline: 1px solid var(--primary-color);
  border-color: transparent;
  color: var(--heading-1st-color) !important;
}
.input-area.second {
  padding: 6px 10px;
  background-color: transparent;
  border: 1px solid var(--border-color);
}
.input-area.second input {
  font-size: 16px;
}
.input-area i {
  color: var(--para-3rd-color);
}
.input-area input,
.input-area textarea {
  border-radius: 0;
  padding: 0;
  border: none;
  background: transparent;
  color: var(--heading-1st-color) !important;
}
.input-area input::placeholder,
.input-area textarea::placeholder {
  opacity: 0.8;
  color: var(--para-2nd-color);
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@keyframes rotation {
  100% {
    transform: rotatey(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes ripple2Ani {
  0% {
    transform: translate(0px, 0px);
  }
  33% {
    transform: translate(-5px, -5px);
  }
  66% {
    transform: translate(5px, 5px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes stretch {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes rippleAni {
  0% {
    transform: translate(0px, 0px);
  }
  33% {
    transform: translate(5px, -5px);
  }
  66% {
    transform: translate(-5px, 5px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
.jw-state-idle .jw-controls {
  background: unset !important;
}

.jw-controls-backdrop {
  background: unset !important;
}

::-webkit-scrollbar {
  width: 8px !important;
  background-color: #f5f5f5 !important;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background-color: var(--scroll-color) !important;
}

.login-page {
  justify-content: center;
  height: 100vh;
}

.registerForm {
  z-index: 99;
  background: rgba(255, 255, 255, 0.3215686275);
  padding: 20px;
  border-radius: 30px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.hrero-section {
  background-image: url("../../images/banner/banner-2.jpg");
}

.header-section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: var(--body-color);
}
.header-section.header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  border-color: transparent;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.3);
  background-color: var(--body-color);
}
.header-section .navbar-toggler {
  padding-right: 0;
  display: none;
  border: none;
}
.header-section .navbar-toggler:focus {
  box-shadow: none;
}
.header-section .navbar-toggler i {
  color: var(--primary-color);
}
.header-section .navbar .search-active {
  border-radius: 50%;
  background: var(--box-1st-color);
  padding: 8px;
}
.header-section .navbar form b i {
  color: var(--para-3rd-color);
}
.header-section .navbar .navbar-nav a {
  text-transform: capitalize;
  font-weight: 600;
  background-color: transparent;
  color: var(--para-3rd-color);
  transition: 0.3s;
}
.header-section .navbar .navbar-nav a i {
  margin: 0;
}
.header-section .navbar .navbar-nav a.active, .header-section .navbar .navbar-nav a:hover {
  color: var(--primary-color);
}
.header-section .navbar .dropdown-toggle {
  position: relative;
  z-index: 1;
}
.header-section .navbar .dropdown-toggle::after {
  position: absolute;
  content: "D";
  border: none;
  font-family: "arafat font";
  font-weight: 400;
  transform: rotate(270deg);
  font-size: 10px;
  bottom: -2px;
  z-index: -1;
}
.header-section .navbar .dropdown-menu {
  padding-right: 20px;
}
.header-section .navbar .dropdown-menu a {
  padding: 3px 15px;
}
.header-section .navbar .dropdown-menu a::after {
  bottom: 1px;
}
.header-section .navbar .dropdown-menu, .header-section .navbar .sub-menu {
  background-color: var(--body-color);
  box-shadow: 0px 12px 12px rgba(44, 44, 77, 0.49);
}
.header-section .navbar .sub-dropdown {
  position: relative;
}
.header-section .navbar .sub-dropdown .sub-menu {
  display: none;
  position: absolute;
  left: 150px;
  top: 0;
  background-color: var(--section-1st-color);
  z-index: 1;
}
.header-section .navbar .sub-dropdown .sub-menu a {
  font-weight: 500;
}
.header-section .navbar .navbar-toggler:focus {
  outline: 0;
  box-shadow: none;
}
.header-section .navbar .show-dropdown {
  margin-right: 15px;
}
.header-section .navbar .show-dropdown:hover .dropdown-menu {
  display: block !important;
}
.header-section .navbar .sub-dropdown:hover .sub-menu {
  display: block !important;
}

.main-content .profile-sidebar {
  background-color: var(--section-1st-color);
  box-shadow: var(--box-shadow);
  border-radius: 10px;
}
.main-content .profile-sidebar .profile-pic .avatar-img {
  border-radius: 20px;
}
.main-content .profile-sidebar .profile-pic .avatar::before {
  position: absolute;
  content: "";
  width: 16px;
  height: 16px;
  background: #21e900;
  border-radius: 50%;
  top: -4px;
  left: -4px;
}
.main-content .profile-sidebar .profile-link {
  border-top: 1px solid var(--bs-border-color);
  border-bottom: 1px solid var(--bs-border-color);
}
.main-content .profile-sidebar .profile-link li {
  margin-top: 15px;
}
.main-content .profile-sidebar .profile-link i {
  color: var(--para-1st-color);
  font-size: 30px;
  transition: 0.3s;
}
.main-content .profile-sidebar .profile-link a {
  transition: 0.3s;
  align-items: center;
}
.main-content .profile-sidebar .admin-tool p {
  color: var(--para-1st-color);
}
.main-content .profile-sidebar .your-shortcuts li {
  margin-top: 28px;
}
.main-content .profile-sidebar .your-shortcuts li img {
  box-shadow: 0px 4px 19px rgba(73, 73, 73, 0.25);
}
.main-content .story-carousel:hover .slick-arrow {
  opacity: 1;
}
.main-content .story-carousel:hover .arafat-prev {
  left: 30px;
}
.main-content .story-carousel:hover .arafat-next {
  right: 30px;
}
.main-content .story-carousel .single-slide {
  margin-right: 10px;
  position: relative;
  cursor: pointer;
}
.main-content .story-carousel .single-slide::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50.93%, #000 100%);
  z-index: 1;
  border-radius: 10px;
}
.main-content .story-carousel span {
  color: var(--bs-white);
}
.main-content .story-carousel .bg-img {
  width: 100%;
}
.main-content .story-carousel .abs-area {
  z-index: 1;
}
.main-content .story-carousel .abs-area .icon-box {
  background: var(--box-1st-color);
  border: 4px solid rgba(0, 133, 255, 0.36);
  border-radius: 10px;
  width: 36px;
  height: 36px;
}
.main-content .story-carousel .abs-area img {
  width: 44px;
  height: 44px;
  border-radius: 10px;
}
.main-content .share-post {
  background: var(--section-1st-color);
  box-shadow: var(--box-shadow);
  border-radius: 10px;
}
.main-content .share-post .abs-area {
  top: 20px;
  right: 25px;
}
.main-content .share-post .abs-area i {
  color: var(--para-1st-color);
}
.main-content .share-post ul li {
  cursor: pointer;
}
.main-content .share-post ul span {
  color: var(--para-1st-color);
}
.main-content .post-item .plyr {
  border-radius: 10px;
}
.main-content .create-group .file-custom {
  background-color: var(--box-1st-color);
}
.main-content .sidebar-area {
  background: var(--section-1st-color);
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  width: 100%;
}
.main-content .sidebar-area .abs-area {
  right: -25px;
  width: 20px;
  height: 20px;
  background: var(--highlight-color);
  border-radius: 50%;
  color: var(--bs-white);
}
.main-content .sidebar-area .friends-list ul {
  margin-left: 8px;
}
.main-content .sidebar-area .friends-list ul li {
  margin-left: -8px;
}
.main-content .sidebar-area .friends-list ul li img {
  border: 1px solid var(--border-color);
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.main-content .sidebar-area .friends-list span {
  margin-left: -8px;
  color: var(--para-1st-color);
}
.main-content .sidebar-area .descript {
  color: var(--para-5th-color);
  font-weight: 400;
}
.main-content .single-box {
  background: var(--section-1st-color);
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  text-align: center;
}
.main-content .single-box .friends-list ul {
  margin-left: 5px;
}
.main-content .single-box .friends-list li {
  margin-left: -5px;
}
.main-content .single-box .friends-list span {
  color: var(--para-1st-color);
}
.main-content .single-box .friends-list img {
  width: 25px;
  border-radius: 50%;
  background: var(--box-1st-color);
}
.main-content .single-box .friends-list .following {
  position: relative;
  margin-left: 15px;
}
.main-content .single-box .friends-list .following::before {
  position: absolute;
  content: "";
  background-color: var(--para-1st-color);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  left: -10px;
}
.main-content .single-box .date-area {
  padding: 6px 10px;
  background: #ff4949;
  border-radius: 10px;
  color: var(--bs-white);
}
.main-content .single-box .price-box {
  padding: 6px 12px;
  background: #ff4949;
  border-radius: 10px;
  color: var(--bs-white);
  bottom: -20px;
  left: 20px;
}
.main-content .single-box.marketplace-item .avatar-item img {
  border-radius: 50%;
}
.main-content .single-box .abs-area h4 {
  color: var(--bs-white);
}
.main-content .single-box .city-area {
  color: var(--para-1st-color);
}
.main-content .single-box .avatar-box::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 30.93%, #000 100%);
  z-index: 0;
  border-radius: 10px;
}
.main-content .single-box .btn-group button i {
  color: var(--heading-1st-color);
}
.main-content .single-box .public-group {
  color: var(--para-1st-color);
}
.main-content .single-box .abs-avatar-item {
  margin-top: -40px;
  transform: translateZ(0);
}
.main-content .single-box .abs-avatar-item img {
  box-shadow: 0px 4px 11px rgba(186, 186, 186, 0.25);
}
.main-content .single-box .btn-border {
  border-top: 1px solid var(--border-color);
}
.main-content .slider-for img {
  width: 100%;
}
.main-content .slider-nav .slide {
  margin: 0 10px;
}
.main-content .star-area i {
  color: #ffba00;
  font-size: 14px;
}
.main-content .star-item i {
  font-size: 14px;
}
.main-content .single-review {
  border-radius: 15px;
  border: 1px solid var(--border-1st-color);
  padding: 20px 20px 15px;
  margin-bottom: 30px;
}
.main-content .delete-btn {
  background: var(--box-1st-color);
  border-radius: 8px;
  width: 45px;
  height: 45px;
}
.main-content table tr {
  vertical-align: middle;
  border-bottom: 1px solid var(--border-color);
}
.main-content table tr th {
  padding: 24px 0;
}
.main-content table td {
  text-align: center;
}
.main-content table .blocked {
  color: var(--para-1st-color);
  font-weight: 400;
}
.main-content .details-area {
  background-color: var(--section-1st-color);
  box-shadow: var(--box-2nd-shadow);
  border-radius: 10px;
}
.main-content .details-area .top-area {
  border-bottom: 1px solid var(--border-color);
}
.main-content .details-area .time-schedule {
  color: var(--primary-color);
}
.main-content .details-area .friends-list ul {
  margin-left: 5px;
}
.main-content .details-area .friends-list li {
  margin-left: -5px;
}
.main-content .details-area .friends-list span {
  color: var(--para-1st-color);
}
.main-content .details-area .friends-list img {
  width: 25px;
  border-radius: 50%;
  background: var(--box-1st-color);
}
.main-content .details-area .tab-content h5 {
  font-weight: 500;
}
.main-content .btn-item .cus-dropdown {
  background: var(--box-1st-color);
  border-radius: 10px;
}
.main-content .description-box ul {
  margin-left: 18px;
  list-style-type: decimal;
}
.main-content .find-tickets {
  background: var(--box-2nd-color);
  border-radius: 10px;
}
.main-content .find-tickets iframe {
  height: 300px;
  border-radius: 5px;
}
.main-content .pages-create img {
  border-radius: 10px;
}
.main-content .pages-create .single-box .btn-group button i {
  color: var(--para-1st-color);
}
.main-content .pages-create .text-area p {
  font-weight: 400;
  color: var(--para-1st-color);
}
.main-content .page-details .nav {
  border-top: 1px solid var(--border-color);
}
.main-content .filter-head button {
  background: var(--section-1st-color);
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  height: 40px;
  width: 40px;
}
.main-content .filter-single-box h5 a {
  color: var(--para-2nd-color);
}
.main-content .filter-single-box p {
  color: var(--para-6th-color);
}
.main-content .member-single p {
  color: var(--para-1st-color);
}
.main-content .top-review-wrapper .icon-wrap {
  width: 40px;
  height: 40px;
  background: #ff4949;
  border-radius: 20px;
}
.main-content .top-review-wrapper .icon-wrap i {
  color: var(--bs-white);
}
.main-content .top-review-wrapper:nth-child(2) .icon-wrap {
  background: #ffb649;
}
.main-content .top-review-wrapper:nth-child(3) .icon-wrap {
  background: #4992ff;
}
.main-content .top-review-wrapper:nth-child(4) .icon-wrap {
  background: #03af33;
}
.main-content .top-review-wrapper .right-side i {
  color: var(--para-1st-color);
}
.main-content .top-review-wrapper.overview-area {
  border-bottom: 1px solid var(--border-1st-color);
}
.main-content .right-summary i {
  font-weight: 700;
  transform: rotateZ(80deg);
  color: #03af33;
}
.main-content .summary-wrapper {
  border-radius: 10px;
  background-color: var(--box-1st-color);
}
.main-content .summary-wrapper .left-side i {
  color: var(--para-2nd-color);
}
.main-content .notification-single {
  border-bottom: 1px solid var(--border-color);
}
.main-content .notification-single a {
  display: inline;
}
.main-content .notification-single:last-child {
  border: none;
}
.main-content .chat-area {
  background: var(--section-1st-color);
  box-shadow: var(--box-shadow);
  border-radius: 5px;
}
.main-content .chat-area .cus-scrollbar {
  max-height: 60vh;
}
.main-content .chat-area aside .close-btn {
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
}
.main-content .chat-area aside .close-btn i {
  color: var(--bs-white);
}
.main-content .chat-area aside .chat-single {
  cursor: pointer;
}
.main-content .chat-area aside .chat-single .btn-group {
  opacity: 0;
  transition: 0.3s;
}
.main-content .chat-area aside .chat-single:hover .btn-group {
  opacity: 1;
}
.main-content .chat-area aside .cus-scrollbar {
  max-height: 64vh;
}
.main-content .chat-area .profile-status span {
  color: #00a469;
}
.main-content .chat-area .main .chat-head {
  background: var(--section-1st-color);
  border-bottom: 1px solid var(--border-color);
}
.main-content .chat-area .main .chat-head img {
  width: 60px;
  height: 60px;
}
.main-content .chat-area .main .chat-head i {
  color: var(--primary-color);
}
.main-content .chat-area .main-chat-box {
  top: initial;
  background-color: var(--section-1st-color);
}
.main-content .chat-area .main-chat-box li {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
  max-width: 70%;
}
.main-content .chat-area .main-chat-box li:last-child {
  margin-bottom: 0;
}
.main-content .chat-area .main-chat-box li img {
  max-width: 35px;
  max-height: 35px;
}
.main-content .chat-area .main-chat-box li .message {
  border-radius: 16px;
  padding: 10px;
  margin: 2px 0;
}
.main-content .chat-area .main-chat-box li.you .message {
  background: var(--box-1st-color);
  border-top-left-radius: 0;
}
.main-content .chat-area .main-chat-box li.me {
  text-align: end;
  align-items: flex-end;
  margin-left: auto;
  justify-content: flex-end;
}
.main-content .chat-area .main-chat-box li.me .message {
  background: #061726;
  border-bottom-right-radius: 0;
}
.main-content .chat-area .main-chat-box li.me p {
  color: var(--bs-white);
}
.main-content .chat-area .main-chat-box li.me span {
  color: rgba(255, 255, 255, 0.52);
}
.main-content .chat-area .main-chat-box span {
  color: var(--para-1st-color);
}
.main-content .chat-area .chat-footer form {
  background-color: var(--box-1st-color);
  border-radius: 10px;
}
.main-content .chat-area .chat-footer input {
  background-color: var(--body-color);
}
.main-content .graph-box {
  background: #0085ff;
  border-radius: 20px;
}
.main-content .graph-box path {
  stroke-width: 3px;
}
.main-content .graph-box.second {
  background-color: #ff1c89;
}
.main-content .graph-box.third {
  background-color: #ffbf1c;
}
.main-content .like-area {
  position: relative;
}
.main-content .like-area::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #7579ff;
  z-index: 1;
  left: -20px;
}
.main-content .post-graph {
  background: linear-gradient(123.42deg, #6b55ee 2.94%, #831bc4 69.88%);
  border-radius: 20px;
}
.main-content .post-graph path {
  stroke-width: 3px;
}
.main-content .post-graph .right-area span {
  position: relative;
}
.main-content .post-graph .right-area span::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 2px;
  background-color: #fff;
  z-index: 1;
  left: -25px;
}
.main-content .post-graph .right-area span.like::before {
  background-color: #55ffb8;
}
.main-content .contact-information .form-group {
  margin-bottom: 15px;
}

.avatar-img {
  border-radius: 20px;
}

.main-content .profile-sidebar .profile-link a:hover span,
.main-content .profile-sidebar .profile-link a.active span,
.main-content .profile-sidebar .profile-link a:hover i,
.main-content .profile-sidebar .profile-link a.active i {
  color: var(--primary-color);
}

.main-content .tab-area a,
.main-content .tab-area button {
  padding: 0;
  padding-right: 20px;
  margin-right: 20px;
  text-transform: capitalize;
  color: var(--para-1st-color);
  position: relative;
  cursor: pointer;
}

.main-content .tab-area a::before,
.main-content .tab-area button::before {
  position: absolute;
  content: "";
  width: 2px;
  height: 50%;
  background-color: var(--border-color);
  right: 0;
  top: 8px;
}

.main-content .tab-area a.active,
.main-content .tab-area button.active,
.main-content .tab-area a:hover,
.main-content .tab-area button:hover {
  color: var(--primary-color);
  transition: 0.3s;
}

.main-content .tab-area li:last-child a::before,
.main-content .tab-area li:last-child button::before {
  display: none;
}

.main-content .top-review-wrapper h5,
.main-content .top-review-wrapper span {
  color: var(--para-1st-color);
}

.main-content .chat-area aside .chat-single:hover,
.main-content .chat-area aside .chat-single.active {
  background-color: var(--box-2nd-color);
}

.main-content .graph-box h2,
.main-content .graph-box .head-color {
  color: var(--bs-white);
}

.main-content .graph-box .apexcharts-xaxis,
.main-content .graph-box .apexcharts-grid {
  display: none;
}

.main-content .post-graph p,
.main-content .post-graph span {
  color: var(--bs-white);
}

.go-live-popup .tooltips-area button {
  background-color: var(--section-1st-color);
  border: 1px solid var(--border-color);
}
.go-live-popup .tooltips-area button i {
  color: var(--para-1st-color);
}

.cmn-modal .modal-dialog {
  max-width: 550px;
}
.cmn-modal .modal-content {
  border-radius: 10px;
  box-shadow: 0px 17px 12px var(--box-shadow);
  background-color: var(--section-1st-color);
  padding: 30px;
}
.cmn-modal .modal-content .modal-body {
  padding: 0;
}
.cmn-modal .modal-content .modal-header {
  position: absolute;
  right: 20px;
  top: 10px;
  padding: 0;
  border: none;
  z-index: 1;
}
.cmn-modal .modal-content .modal-header .btn-close {
  background: none;
}
.cmn-modal .modal-content .modal-header .btn-close:focus {
  box-shadow: none;
}
.cmn-modal .modal-content .modal-header .btn-close:hover i {
  color: var(--highlight-color);
  transition: 0.3s;
}

.modal-header {
  justify-content: space-between;
}

.cart-content thead {
  background-color: var(--box-2nd-color);
}
.cart-content thead th {
  text-align: center;
  color: var(--para-2nd-color);
}
.cart-content tbody {
  background-color: var(--box-2nd-color);
}
.cart-content tbody tr {
  border-top: 1px solid var(--border-1st-color);
}
.cart-content tbody th {
  padding: 30px 20px;
  width: 35%;
  text-align: start;
}
.cart-content .img-area {
  margin-left: 15px;
  margin-right: 10px;
  max-width: 90px;
  max-height: 90px;
}
.cart-content .img-area img {
  border-radius: 5px;
}
.cart-content .qtySelector {
  border-radius: 10px;
  background-color: var(--box-2nd-color);
  border: 1px solid rgba(88, 88, 125, 0.35);
}
.cart-content .qtySelector i {
  cursor: pointer;
  color: var(--para-2nd-color);
  min-width: 32px;
  min-height: 32px;
  font-size: 18px;
  border-radius: 50%;
  background-color: var(--btn-3rd-color);
}
.cart-content .qtySelector input {
  font-size: 18px;
}
.cart-content .qtySelector .qtyValue {
  border: none;
  padding: 5px;
  width: 60px;
  background: transparent;
  font-weight: bold;
  color: var(--para-2nd-color);
}
.cart-content i {
  color: var(--para-2nd-color);
}
.cart-content .sub-total {
  background-color: var(--box-1st-color);
  padding: 30px;
}

.cart-content tbody td,
.cart-content tbody tr {
  color: var(--para-2nd-color);
  vertical-align: middle;
  text-align: center;
}

@media (min-width: 992px) {
  .main-content.sidebar-content .cus-overflow {
    overflow: hidden;
  }
  .main-content.sidebar-content .profile-sidebar,
  .main-content.sidebar-content .contact-sidebar {
    overflow-x: hidden;
    width: 100%;
    transition: all 0.5s;
  }
  .main-content.sidebar-content .profile-sidebar.active {
    width: 100px;
  }
  .main-content.sidebar-content .profile-sidebar.active .profile-pic,
  .main-content.sidebar-content .profile-sidebar.active a {
    justify-content: flex-end;
  }
  .main-content.sidebar-content .profile-sidebar.active .profile-pic i,
  .main-content.sidebar-content .profile-sidebar.active a i {
    font-size: 40px;
  }
  .main-content.sidebar-content .profile-sidebar.active .your-shortcuts span,
  .main-content.sidebar-content .profile-sidebar.active .your-shortcuts h6,
  .main-content.sidebar-content .profile-sidebar.active .text-area,
  .main-content.sidebar-content .profile-sidebar.active span {
    transition: all 0.3s;
    visibility: hidden;
    opacity: 0;
    width: 0;
    height: 0;
  }
  .main-content.sidebar-content .contact-sidebar.active {
    width: 100px;
    margin: auto;
    margin-right: 0;
    transition: 0.3s;
  }
  .main-content.sidebar-content .contact-sidebar.active .avatar-item {
    gap: 0 !important;
  }
  .main-content.sidebar-content .contact-sidebar.active .profile-area {
    justify-content: center !important;
  }
  .main-content.sidebar-content .contact-sidebar.active .title-bar,
  .main-content.sidebar-content .contact-sidebar.active .abs-area,
  .main-content.sidebar-content .contact-sidebar.active .info-area,
  .main-content.sidebar-content .contact-sidebar.active .btn-group {
    transition: all 0.3s;
    visibility: hidden;
    opacity: 0;
    width: 0;
    height: 0;
  }
  .main-content.sidebar-content .col-xxl-3 {
    width: 25% !important;
  }
}
@media (max-width: 1199px) {
  .sidebar-toggler {
    position: relative;
  }
  .sidebar-toggler .cus-scrollbar {
    top: 40px;
  }
  .sidebar-toggler .sidebar-head {
    position: absolute;
    overflow: hidden;
    right: 0;
    top: 0;
  }
  .sidebar-toggler .sidebar-head.active {
    z-index: 1;
  }
  .sidebar-toggler .sidebar-head.active .side-wrapper {
    visibility: visible;
    opacity: 1;
    transform: translateX(0px);
  }
  .sidebar-toggler .sidebar-head .side-wrapper {
    transform: translateX(200px);
    opacity: 0;
    right: 0;
    visibility: hidden;
    transition: 0.3s;
  }
  .sidebar-toggler .sidebar-head .sidebar-area {
    width: 100%;
  }
  .sidebar-toggler .sidebar-head .sidebar-wrapper {
    min-width: 300px;
  }
  .sidebar-toggler .sidebar-head .sidebar-wrapper.max-width {
    max-width: 300px;
  }
  .main-content .cus-scrollbar.contact-sidebar-head {
    position: absolute;
    top: 115px;
    right: 0;
  }
  .main-content .cus-scrollbar.contact-sidebar-head .contact-sidebar {
    transform: translateX(200px);
    opacity: 0;
  }
  .main-content .cus-scrollbar.contact-sidebar-head .contact-sidebar.active {
    transform: translateX(0px) translateY(-20px);
    opacity: 1;
  }
  .cart-table .table {
    width: 133%;
  }
}
@media (min-width: 1699px) {
  .main-content.sidebar-content .col-xxl-3 {
    width: 18% !important;
  }
}
@media (max-width: 1399px) {
  .banner-section .overlay .banner-content {
    padding: 110px 0 30px;
  }
  .main-content .find-tickets iframe {
    height: 230px;
    border-radius: 5px;
  }
}
@media (max-width: 1199px) {
  h1,
  h1 > a {
    font-size: 57px;
    line-height: 74.1px;
    margin-top: -16px;
  }
  h2,
  h2 > a {
    font-size: 43px;
    line-height: 55.9px;
    margin-top: -10px;
  }
  .preloader {
    background-size: 30%;
  }
  .header-section .navbar .navbar-brand {
    margin-right: 12px;
  }
  .header-section .navbar .navbar-nav a {
    font-size: 16px;
  }
  .header-section .navbar .navbar-collapse .right-area {
    margin-left: 30px;
  }
  .main-content .sidebar-wrapper {
    align-items: baseline;
  }
}
@media (max-width: 991px) {
  h1,
  h1 > a {
    font-size: 57px;
    line-height: 68.4px;
    margin-top: -16px;
  }
  h2,
  h2 > a {
    font-size: 43px;
    line-height: 51.6px;
    margin-top: -10px;
  }
  h3,
  h3 > a {
    font-size: 32px;
    line-height: 38.4px;
    margin-top: -8px;
  }
  h4,
  h4 > a,
  .xxltxt {
    font-size: 24px;
    line-height: 28px;
    margin-top: -6px;
  }
  h5,
  h5 > a,
  .xltxt {
    font-size: 18px;
    line-height: 22px;
    margin-top: -4px;
  }
  h6,
  h6 > a,
  table thead tr th,
  button,
  a,
  p,
  span,
  li {
    font-size: 16px;
    line-height: 22px;
  }
  .section-header {
    margin-bottom: 40px;
  }
  .title {
    margin-bottom: 20px;
  }
  .lgtxt {
    font-size: 20px;
    line-height: 28px;
  }
  .cmn-btn {
    padding: 4px 12px;
  }
  .cmn-btn [class*=icon-]:before {
    line-height: 1;
  }
  .header-section .navbar-toggler {
    display: inline-block;
  }
  .header-section .navbar .navbar-brand img {
    max-width: initial;
  }
  .header-section .navbar .navbar-collapse .navbar-nav {
    max-height: 250px;
    margin: 20px 0;
    overflow-y: auto;
    margin-bottom: 10px;
    overflow-x: hidden;
  }
  .header-section .navbar .dropdown-menu {
    column-count: 1;
  }
  .header-section .navbar .dropdown-toggle::after {
    z-index: 0;
  }
  .header-section .dropend {
    margin-left: 18px;
  }
  .header-section .navbar .navbar-collapse .right-area {
    margin-left: 0;
  }
  .banner-section .overlay .sec-img {
    display: none;
  }
  .banner-section .overlay .banner-content {
    padding: 160px 0 110px;
  }
  .banner-section.inner-banner .banner-content {
    padding: 160px 0 60px;
  }
  .header-section .navbar .navbar-collapse .right-area .search-form {
    left: 0;
    top: 50px;
  }
  .sec-img {
    text-align: center;
    margin-top: 30px;
    max-width: 65%;
  }
  .sec-img img {
    max-width: 100% !important;
  }
  .main-content {
    position: relative;
    overflow-x: hidden;
    padding-bottom: 66px;
  }
  .main-content .profile-sidebar {
    position: absolute;
    top: initial;
    z-index: 2;
    min-width: 290px;
    transition: 0.3s;
  }
  .main-content .profile-sidebar.active {
    transform: translateX(0px);
    opacity: 1;
  }
  .main-content .profile-sidebar.max-width {
    max-width: 300px;
  }
  .header-menu .single-item .main-area {
    top: initial;
    right: initial;
    bottom: 70px;
    transform: translateY(-70px);
  }
  .header-menu .single-item .main-area.active {
    transform: translateY(0);
  }
  .header-menu .single-item.profile-area .main-area {
    right: 0;
  }
  .main-content .single-box .table.group-privacy-table {
    width: 100%;
  }
  .main-content .contact-sidebar-head {
    position: absolute;
    overflow-x: initial;
  }
  .main-content .contact-sidebar-head .contact-sidebar {
    transform: translateX(200px);
    opacity: 0;
    right: 0;
    transition: 0.3s;
  }
  .main-content .contact-sidebar-head .contact-sidebar.active {
    opacity: 1;
    transform: translateX(0px);
  }
  .main-content .contact-sidebar-head .sidebar-area {
    width: 100%;
  }
  .main-content .contact-sidebar-head .sidebar-wrapper {
    min-width: 300px;
  }
}
@media (max-width: 767px) {
  .input-area {
    padding: 15px 10px;
  }
  .header-menu .single-item.notification-area .main-area {
    min-width: 290px;
    white-space: initial;
    right: 20%;
  }
  .main-content .single-box .table {
    width: 115%;
  }
  .header-section .navbar .navbar-brand img {
    max-width: 40px;
  }
  .main-content #memberRequest,
  .main-content #postChart {
    margin-bottom: -15px;
    margin-left: -10px;
  }
}
@media (max-width: 575px) {
  h1,
  h1 > a {
    font-size: 40px;
    line-height: 46px;
    margin-top: -9px;
  }
  h2,
  h2 > a {
    font-size: 30px;
    line-height: 36px;
    margin-top: -7px;
  }
  h3,
  h3 > a {
    font-size: 24px;
    line-height: 28px;
    margin-top: -6px;
  }
  h4,
  h4 > a {
    font-size: 20px;
    line-height: 24px;
    margin-top: -4px;
  }
  a,
  p,
  span,
  li,
  .cmn-btn,
  thead tr th,
  tbody tr th,
  tbody tr td,
  input,
  label,
  textarea,
  h6,
  h6 > a {
    font-size: 15px;
    line-height: 19px;
  }
  .section-header {
    margin-bottom: 35px;
  }
  .title {
    margin-bottom: 15px;
  }
  .sub-title {
    margin-bottom: 14px;
  }
  .xxltxt {
    font-size: 20px;
    line-height: 24px;
  }
  .xltxt {
    font-size: 18px;
    line-height: 25.5px;
  }
  .lgtxt {
    font-size: 15px;
    line-height: 22.4px;
  }
  .fs-xxl {
    font-size: 24px;
  }
  .main-content .post-item .comments-area .comment-item-nested .avatar-item img,
  .main-content .post-item .comments-area .single-comment-area .parent-comment .avatar-item img {
    width: 35px;
  }
  .main-content .post-item .comment-form {
    margin-top: 10px;
  }
  .main-content .single-box .table {
    width: 135%;
  }
  .main-content .post-item .comments-area .single-comment-area .parent-comment .avatar-item:before {
    top: 50px;
  }
  .main-content .post-item .comments-area .single-comment-area .sibling-comment:before,
  .main-content .post-item .comments-area .comment-item-nested .avatar-item:before {
    left: -35px;
  }
  .main-content #memberRequest,
  .main-content #postChart {
    margin-bottom: -5px;
  }
  .magnific-area .mfp-iframe {
    width: 40px;
    height: 40px;
  }
  .header-section .navbar .navbar-nav a i {
    font-size: 32px;
  }
  .single-input label {
    margin-bottom: 5px;
  }
}
@media (max-width: 480px) {
  .header-menu .single-item.notification-area .main-area {
    right: 5px;
    left: 5px;
  }
  .friend-request .col-8 {
    width: 100% !important;
  }
  .main-content .single-box .table {
    width: 225%;
  }
  .main-content #memberRequest,
  .main-content #postChart {
    margin-bottom: -15px;
  }
  .main-content .chat-area .main-chat-box li {
    max-width: 95%;
    gap: 10px;
  }
  .cart-table .table {
    width: 230%;
  }
}
@media (max-width: 375px) {
  .setting-row .col-6 {
    width: 70% !important;
  }
  .profile-picture-area .col-8,
  .graph-col .col-6 {
    width: 100% !important;
  }
}
.content-area {
  padding: 40px 0;
  background: var(--body-color);
}

.modal-content {
  border-radius: 10px;
  box-shadow: 0px 17px 12px var(--box-shadow) !important;
  background-color: var(--section-1st-color) !important;
  /* padding: 30px; */
}

.fade {
  transition: opacity 0.15s linear;
}

.dropdown-menu {
  background-color: var(--section-1st-color) !important;
  box-shadow: 0px 17px 12px var(--box-shadow) !important;
  border-radius: 10px !important;
  border-color: var(--bs-primary) !important;
  z-index: 1 !important;
}
.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus {
  background-color: rgba(171, 171, 171, 0.3411764706) !important;
}

.input-group-text {
  color: var(--heading-1st-color) !important;
}

.input-group {
  flex-wrap: nowrap !important;
}
.input-group .input-group-prepend {
  background-color: var(--box-1st-color) !important;
  border-radius: 10px;
}
.input-group .input-group-prepend .input-group-text {
  background-color: transparent;
  opacity: 0.5;
  color: #a6acd1;
}

select.input-area {
  min-height: 56px;
}

.c-pointer {
  cursor: pointer !important;
}

.c-text {
  cursor: text !important;
}

.outline-0 {
  outline: none;
}

.offcanvas {
  background-color: var(--section-1st-color) !important;
  width: 300px !important;
  max-width: 90% !important;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.form-group {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 16px;
}
.form-group .form-label {
  text-align: start;
}
.form-group .invalid-feedback {
  display: flex;
  position: absolute;
  right: 16px;
  bottom: -2px;
  justify-content: flex-end;
}