.fw-300{
    font-weight: 300;
}
.fw-400{
    font-weight: 400;
}
.fw-500{
    font-weight: 500;
}
.fw-600{
    font-weight: 600;
}

.fs-xxxl {
	font-size: 40px;
}

.fs-xxl {
	font-size: 34px;
}
.fs-xl {
	font-size: 30px;
}
.fs-lg {
	font-size: 24px;
}
.fs-md {
	font-size: 16px;
}
